'use strict';

Gri.module({
	name: 'header-bottom',
	ieVersion: null,
	$el: $('.header-bottom'),
	container: '.header-bottom',
	fn: function () {

		$(".header-bottom-inner-search-list").mCustomScrollbar();

		$(".header-mobil-inner nav ul li > ul.sub").mCustomScrollbar();

		$(".header-bottom-inner-search-content-button").on("click", function(){
			if ($("html").attr('lang') == 'tr') {
				window.location.href = "/arama/?search=" + $(".header-bottom-inner-search-content-input").val();
			}else {
				window.location.href = "/search/?search=" + $(".header-bottom-inner-search-content-input").val();
			}
		});

		$(".header-mobil-search-button").on("click", function () {
			if ($("html").attr('lang') == 'tr')
			{
				window.location.href = "/arama/?search=" + $(".header-mobil-search-input").val();
			}
			else{
				window.location.href = "/search/?search=" + $(".header-mobil-search-input").val();
			}
		});

		function seeAllButtonClicked() {
			$(".header-bottom-inner-search-list-see-all").on("click", function (e) {
				e.preventDefault();
				if ($("html").attr('lang') == 'tr') {
					window.location.href = "/arama/?search=" + $(".header-mobil-search-input").val();
				}else {
					window.location.href = "/search/?search=" + $(".header-mobil-search-input").val();
				}
			});
		}

		$(window).on("keyup", function(e){
			if(e.which == 13) {
				if ($(".header-bottom-inner-menu-search-item").hasClass("header-bottom-inner-menu-search-item-close") ) {
					if ($("html").attr('lang') == 'tr') {

						window.location.href = "/arama/?search=" + $(".header-bottom-inner-search-content-input").val();
					} else {
						window.location.href = "/search/?search=" + $(".header-bottom-inner-search-content-input").val();

					}
				}
			}
		});

		$(".header-bottom-inner-menu-search-item").on("click", function(){
			$(this).toggleClass("header-bottom-inner-menu-search-item-close");
			$(".header-bottom-inner-search-content").toggleClass("header-bottom-inner-search-content-active");
		});

		$(".header-bottom-inner-search-content-input").on("keyup", function(){
			$(".header-bottom-inner-search-list").addClass("header-bottom-inner-search-list-active");
			var lang = $('html').attr("lang"),
				searchUrl = "/arama-sonuclari/?search=";
			if(lang == "en") {
				searchUrl = "/search-result/?search=";
				console.log(searchUrl);
			}

			if($(this).val().length >= 3) {
				setTimeout(() => {
					$.ajax(searchUrl + encodeURIComponent( $(this).val() ) ).success(function (results) {
						$('.header-bottom-inner-search-list-inner a').remove();
						$('.header-bottom-inner-search-list-inner p').remove();
						$('.header-bottom-inner-search-list-inner').append(results);
					});
				}, 500);
			} else {
				$('.header-bottom-inner-search-list-inner a').remove();
				$('.header-bottom-inner-search-list-inner p').remove();
			}
		});

		$(window).click(function(e) {
			var list = $(".header-bottom-inner-search-list");
			var activeList = "header-bottom-inner-search-list-active";
			if(e.target.className == "header-bottom-inner-search-list-item" || e.target.className == "header-bottom-inner-search-content-input") {
				$(".header-bottom-inner-search-list").addClass("header-bottom-inner-search-list-active");
			} else {
				$(".header-bottom-inner-search-list").removeClass("header-bottom-inner-search-list-active");
			}
		});




		$(".header-mobil-search-input").on("keyup", function(){
			if($(this).val().length >= 3) {
				$.ajax("/arama-sonuclari/?search=" + encodeURIComponent( $(this).val() ) ).success(function (results) {
					$('.header-mobil-search-list a').remove();
					$('.header-mobil-search-list p').remove();
					$('.header-mobil-search-list').append(results);
					seeAllButtonClicked();
				});
			} else {
				$('.header-mobil-search-list a').remove();
				$('.header-mobil-search-list p').remove();
			}
		});



	}
});
